var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-layout",
            { staticClass: "u-background-blue col-12 service-section-title" },
            [
              _c("h3", { staticClass: "white--text pl-4 text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("VehicleInformation"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "col-12" },
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { xs: "6", sm: "4", md: "3" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("Year")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.years,
                      placeholder: _vm.$t("Year"),
                      outlined: "",
                      rules: [
                        function () {
                          return !!_vm.vehicleYear || _vm.$t("YearRequired")
                        },
                      ],
                    },
                    on: { change: _vm.yearChanged },
                    model: {
                      value: _vm.vehicleYear,
                      callback: function ($$v) {
                        _vm.vehicleYear = $$v
                      },
                      expression: "vehicleYear",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pr-0",
              attrs: { cols: "12", xs: "6", sm: "4", md: "3" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("MakeWithStar")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.vehicleYear === "",
                      items: _vm.$store.state.appointment.makeList,
                      loading: _vm.isLoading,
                      placeholder: _vm.$t("MakeWithStar"),
                      rules: [
                        function () {
                          return !!_vm.selectedMake || _vm.$t("MakeRequired")
                        },
                      ],
                      outlined: "",
                    },
                    on: { change: _vm.makeChanged },
                    model: {
                      value: _vm.selectedMake,
                      callback: function ($$v) {
                        _vm.selectedMake = $$v
                      },
                      expression: "selectedMake",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "col-12" },
        [
          _c(
            "v-col",
            {
              staticClass: "pr-0",
              attrs: { cols: "12", xs: "6", sm: "4", md: "3" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("ModelWithStar")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-combobox", {
                    attrs: {
                      disabled: _vm.selectedMake === "",
                      items: _vm.$store.state.appointment.models,
                      placeholder: _vm.$t("ModelWithStar"),
                      rules: [
                        function () {
                          return !!_vm.modelSelected || _vm.$t("ModelRequired")
                        },
                      ],
                      outlined: "",
                      loading: _vm.isLoading,
                    },
                    on: { change: _vm.modelChanged },
                    model: {
                      value: _vm.modelSelected,
                      callback: function ($$v) {
                        _vm.modelSelected = $$v
                      },
                      expression: "modelSelected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pr-0",
              attrs: { cols: "12", xs: "6", sm: "4", md: "3" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("LicenseNumber")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "License number",
                      outlined: "",
                      sm: "2",
                      md: "2",
                    },
                    on: { change: _vm.changeLicense },
                    model: {
                      value: _vm.licenseNumber,
                      callback: function ($$v) {
                        _vm.licenseNumber = $$v
                      },
                      expression: "licenseNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "col-12 px-2 mx-auto" },
        [
          _c("image-uploader-preview", { attrs: { imagePos: "0" } }),
          _c("image-uploader-preview", { attrs: { imagePos: "1" } }),
          _c("image-uploader-preview", { attrs: { imagePos: "2" } }),
        ],
        1
      ),
      _c("v-row", { staticClass: "col-12" }, [_c("v-divider")], 1),
      _c(
        "v-row",
        { staticClass: "col-12" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "v-btn",
                {
                  attrs: { elevation: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "0",
                    color: "u-background-primary white--text",
                    loading: _vm.loadingMakeAppointmentBtn,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.next()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Next")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290", persistent: "" },
          model: {
            value:
              _vm.$store.state.appointment.appointment.isAppointmentScheduled,
            callback: function ($$v) {
              _vm.$set(
                _vm.$store.state.appointment.appointment,
                "isAppointmentScheduled",
                $$v
              )
            },
            expression:
              "$store.state.appointment.appointment.isAppointmentScheduled",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "pt-4 pb-0" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("ThankForYourOrder")) + " "),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "u-background-primary white--text mx-auto",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }