import { render, staticRenderFns } from "./AddressInformation.vue?vue&type=template&id=60d3bcfb&"
import script from "./AddressInformation.vue?vue&type=script&lang=ts&"
export * from "./AddressInformation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60d3bcfb')) {
      api.createRecord('60d3bcfb', component.options)
    } else {
      api.reload('60d3bcfb', component.options)
    }
    module.hot.accept("./AddressInformation.vue?vue&type=template&id=60d3bcfb&", function () {
      api.rerender('60d3bcfb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/services/mobileService/AddressInformation.vue"
export default component.exports