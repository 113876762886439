var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { staticClass: "col-12 pb-0 u-background-blue service-section-title" },
        [
          _c(
            "v-btn",
            {
              staticStyle: { top: "-5px" },
              attrs: { icon: "", to: { name: "firstStep" } },
            },
            [
              _c("v-icon", { attrs: { color: "white" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
            ],
            1
          ),
          _c(
            "h3",
            {
              staticClass: "white--text p-absolute text-uppercase",
              staticStyle: { position: "relative" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Address")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "col-12" },
        [
          _c(
            "v-row",
            { staticClass: "col-12" },
            [
              _c(
                "v-col",
                {
                  staticClass: "pr-0",
                  attrs: { cols: "12", xs: "6", sm: "4", md: "4" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "u-blue-text font-family-rubik font-weight-bold",
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("AddressWithStar")) + " ")]
                  ),
                  _c("location-auto-complete", {
                    attrs: {
                      isDropOff: false,
                      isNotTowing: true,
                      readOnly: _vm.readonly,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pr-0",
                  attrs: { cols: "12", xs: "6", sm: "4", md: "4" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "d-flex justify-content-space-between" },
                    [
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "primary-font-style font-weight-bold u-blue-text",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("EstimatedCharge")) + " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "primary-font-style font-weight-bold text-right\n                     u-blue-text",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.$store.state.serviceCategory
                                        .serviceSelected
                                        .totalPriceSubcategoriesSelected
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "col-12" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "6", sm: "12", md: "12", lg: "4" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "u-blue-text font-family-rubik font-weight-bold",
                      attrs: { outlined: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("AddNoteForDriver")) + " ")]
                  ),
                  _c(
                    "v-layout",
                    [
                      _c("v-textarea", {
                        attrs: { outlined: "" },
                        on: {
                          change: function ($event) {
                            return _vm.changeNoteEntered()
                          },
                        },
                        model: {
                          value: _vm.note,
                          callback: function ($$v) {
                            _vm.note = $$v
                          },
                          expression: "note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "6", sm: "12", md: "12", lg: "6" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "u-blue-text font-family-rubik font-weight-bold",
                      attrs: { outlined: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "ClickOnTheCalendarToPickADateTimeForTheAppointment"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("appointment-date-time"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }